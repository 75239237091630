import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/scss/main.scss'

const Layout = ({ children, location }) => {

  let content;

  if (location && location.pathname === '/') {
    content = (
      <div>
        {children}
      </div>
    )
  } else {
    content = (
      <div id="wrapper" className="page">
        <div>
          {children}
        </div>
      </div>
    )
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: 'Victor Digital Portfolio | Victor Tan Qin Wei - Singapore' },
              { name: 'keywords', content: 'digital, portfolio, singapore' },
            ]}
          >
            <html lang="en" />
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://victorx.space/" />
            <meta property="og:title" content="Victor | Digital Portfolio" />
            <meta property="og:description" content="Digital Portfolio | Victor Tan Qin Wei | Singapore | Digital Marketing | SEO | Product Marketing"/>
            <meta property="og:image" content="https://i.imgur.com/8hFWqXW.png"/>
            <meta property="twitter:card" content="summary_large_image"/>
            <meta property="twitter:url" content="https://victorx.space/"/>
            <meta property="twitter:title" content="Victor - Digital Portfolio"/>
            <meta property="twitter:description" content="Digital Portfolio | Victor Tan Qin Wei | Singapore" />
            <meta property="twitter:image" content="https://i.imgur.com/8hFWqXW.png" />

          </Helmet>
          {content}
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
